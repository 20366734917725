const VENDOR_MANAGER = ['tools-vendor-manager', 'tools-vendor-manager-suggested', 'users-username-settings-type', 'tools-vendor-manager-hired']
const VENDOR_MANAGER_SETUP = ['tools-vendor-manager-setup']

export default function ({ $auth, redirect, store, route }) {
    if (route.params.slug === 'wedding-checklist' || route.name.includes('wedding-checklist')) {
        if ($auth.loggedIn) {
            if (store?.state?.auth?.user?.checklist_setup_at) {
                if (['complete-slug-step', 'complete-slug'].includes(route.name)) redirect('/tools/wedding-checklist')
            } else if (route.name !== 'complete-slug-step') {
                redirect('/complete/wedding-checklist/1')
            }
        } else if (route.name !== 'tools-wedding-checklist-setup') {
            redirect('/tools/wedding-checklist/setup')
        }
    }
    if (route.params.slug === 'guest-list' || route.name.includes('guest-list')) {
        if ($auth.loggedIn) {
            if (store?.state?.auth?.user?.guestlist_setup_at) {
                if (['complete-slug-step', 'complete-slug'].includes(route.name)) redirect('/tools/guest-list')
            } else if (route.name !== 'complete-slug-step') {
                redirect('/complete/guest-list/1')
            }
        } else if (route.name !== 'tools-guest-list-setup') redirect('/tools/guest-list/setup')
    }

    if (route.name.includes('vision-boards') || route.name.includes('users-username-boards') || route.name.includes('users-username-slug')) {
        if ($auth.loggedIn) {
            if (store?.state?.auth?.user?.saved_ideas_setup_at) {
                if (route.name === 'tools-vision-boards-setup' || route.name === 'tools-vision-boards-setup-step')
                    redirect(`/users/${store?.state?.auth?.user?.username}/boards`)
            } else if (route.name === 'tools-vision-boards-setup') {
                redirect('/tools/vision-boards/setup/1')
            } else if (route.name === 'users-username-boards' && store?.state?.auth?.user?.username === route.params?.username) {
                redirect('/tools/vision-boards/setup/1')
            } else if (route.name === 'users-username-slug') {
                redirect('/tools/vision-boards/setup/1')
            }
        } else if (route.name !== 'tools-vision-boards-setup') redirect('/tools/vision-boards/setup')
    }

    if (route.name.includes('vendor-manager')) {
        if ($auth.loggedIn) {
            if (!VENDOR_MANAGER.includes(route.name)) redirect('/tools/vendor-manager')
        } else if (!VENDOR_MANAGER_SETUP.includes(route.name)) redirect('/tools/vendor-manager/setup')
    }
}
