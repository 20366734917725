
import inputIcon from '~/mixins/inputIcon'

export default {
    mixins: [inputIcon],

    props: {
        type: {
            type: String,
            default: 'text',
        },
        classes: {
            type: String,
            default: '',
        },
        value: {
            type: null,
            default: undefined,
        },
        hint: {
            type: String,
            default: undefined,
        },
        help: {
            type: String,
            default: undefined,
        },
        fontFamily: {
            type: String,
            default: 'fas',
        },
        placeholder: {
            type: [String, Number],
            default: undefined,
        },
        minLength: {
            type: null,
            default: null,
        },
        min: {
            type: [String, Number],
            default: null,
        },
        search: Boolean,
        required: Boolean,
        disabled: Boolean,
        hidden: Boolean,
        round: Boolean,
        mono: Boolean,
        xxs: Boolean,
        xxxs: Boolean,
        xs: Boolean,
        sm: Boolean,
        lg: Boolean,
        xl: Boolean,
    },

    data() {
        return {
            clickable: false,
        }
    },

    computed: {
        id() {
            return `input-${this.type}-${this._uid}`
        },

        isDate() {
            return this.type === 'date'
        },

        isTime() {
            return this.type === 'time'
        },

        isNumber() {
            return this.type === 'number'
        },

        inputClasses() {
            return [this.classes, {
                'hidden-input py-0-i': this.hidden,
                'input-mono': this.mono,
                'rounded-full': this.round,
                'pr-11': this.icon && !this.prepend,
                'pl-11': this.isDate || this.isTime || (this.icon && this.prepend) || this.search,
                'px-0': this.hidden && !this.isDate && !this.isTime && !this.icon,
                'input-xxxs': this.xxxs,
                'input-xxs': this.xxs,
                'input-xs': this.xs,
                'input-sm': this.sm,
                'input-lg': this.lg,
                'input-xl': this.xl,
                'appearance-none': this.isTime || this.isDate
            }]
        },
        isPrepend(){
            return this.prepend || this.isDate || this.isTime
        }
    },

    watch: {
        value: {
            handler() {
                this.isValid(this.value || '')
            },
            immediate: true,
        },
    },

    methods: {
        onClick() {
            if(this.disabled) return
            if (this.isDate || this.isTime) this.$refs.input.showPicker()
            else if (this.clickable) this.$emit('click')
        },

        onKeyUp(e) {
            if(this.disabled) return
            if (e.keyCode === 13) this.$emit('enter')
        },

        onKeyDown(e) {
            if(this.disabled) return
            if (this.isNumber) {
                if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
                    return
                }
            }
        },

        onInput(e) {
            if(this.disabled) return
            if (this.isNumber && Number(this.min) >= 0 && e.target.value < 0) this.$emit('input', Math.abs(e.target.value))
            else this.$emit('input', e.target.value)
        },

        isValid(value) {
            if (this.type === 'tel') {
                const regex = /^[+][1] (\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
                this.$emit('valid', value.match(regex))
            }
        },

        onFocus(e) {
            if(this.disabled) return
            this.$emit('focus')
        },

        onBlur(e) {
            if(this.disabled) return
            if (this.type === 'tel') {
                let val = this.value || ''
                val = val
                    .replace('+1', '')
                    .trim()
                    .replace(/\D/g, '')
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)

                val = !val[2] ? val[1] : '+1 (' + val[1] + ') ' + val[2] + (val[3] ? '-' + val[3] : '')
                this.$emit('input', val)
            }
            this.$emit('blur', e)
        },
    },
}
