import Vue from 'vue'
import { parseISO, format } from 'date-fns'

Vue.mixin({
    data() {
        return {
            _birdieInst: null,
        }
    },

    methods: {
        _loadBirdie() {
            if (!process.client) return
            if (document.getElementById('birdie-script')) return
            const script = document.createElement('script')
            script.async = true
            script.id = 'birdie-script'
            script.dataset.subdomain = this.$config.birdieSubdomain
            script.src = 'https://cdn.sendbirdie.com/lib/1.1.0/birdie-registry-sandbox.js'
            document.head.appendChild(script)
            return new Promise((resolve) => {
                script.onload = resolve
            })
        },

        async $birdie(params = {}, user = null, callback = null) {
            if (!this.$config.birdieEnabled) return null
            // Occassions: Wedding, Bar Mitzvah, Birthday, Bat Mitzvah, Bridal Shower, Baby Shower, Graduation, Other
            if (!process.client) return this._birdieInst
            if (this._birdieInst) return this._birdieInst
            const birdieData = user?.birdie_data || {}
            const weddingDetails = user?.wedding_details || {}
            const now = new Date()
            const nowDate = format(now, 'yyyy-MM-dd')
            const weddingDate = format(parseISO(weddingDetails?.date || nowDate), 'yyyy-MM-dd')
            let title = ''
            const extra = {}
            if (birdieData && birdieData.identifier) extra.registryId = birdieData.identifier
            if (user?.firstname && weddingDetails?.other_firstname) title = `${user.firstname} & ${weddingDetails?.other_firstname} Registry`
            await this._loadBirdie()
            try {
                if (window && window.BirdieRegistry !== 'undefined' && typeof window.BirdieRegistry === 'function') {
                    this._birdieInst = window.BirdieRegistry({
                        clientId: this.$config.birdieClientId,
                        themeKey: this.$config.birdieThemeKey,
                        ...extra,
                        // registrySlug -> gift sender
                        // registryId --> edit
                        prefilledData: {
                            registry: {
                                recipient: user?.firstname || '',
                                recipient_last_name: user?.lastname || '',
                                recipient_2_first_name: weddingDetails?.other_firstname || '',
                                recipient_2_last_name: weddingDetails?.other_lastname || '',
                                date: weddingDate,
                                title: title,
                                // description: '',
                                // slug: '',
                            },
                            user: {
                                email: user?.email || '',
                                phone: user?.phone || '',
                            },
                        },
                        onRegistryCreate: (obj) => {
                            const update = { id: user?.id, birdie_data: obj }
                            if (update.id) {
                                // obj.public_token, obj.slug, obj.identifier
                                this.$store.dispatch('user/update', update).then(() => {
                                    this.$nextTick(() => {
                                        this.$toast.success("Woohoo! We've saved your changes.").goAway(3000)
                                        this.$emit('saved')
                                    })
                                })
                            }
                            if (callback) callback(true)
                        },
                        onRegistryDelete: (obj) => {
                            // obj.identifier
                            const update = { id: user?.id, birdie_data: null }
                            if (update.id)
                                this.$store.dispatch('user/update', update).then(() => {
                                    this.$nextTick(() => {
                                        this.$toast.success("Woohoo! We've saved your changes.").goAway(3000)
                                        this.$emit('saved')
                                    })
                                })
                        },
                        onRegistryUpdate: (obj) => {
                            const update = { id: user?.id, birdie_data: user.birdie_data }
                            update.birdie_data.gift_total = obj.gift_total
                            update.birdie_data.total_gifters = obj.total_gifters
                            if (update.id)
                                this.$store.dispatch('user/update', update).then(() => {
                                    this.$nextTick(() => {
                                        this.$toast.success("Woohoo! We've saved your changes.").goAway(3000)
                                        this.$emit('saved')
                                    })
                                })
                        },
                        onCancel: () => {
                            if (callback) callback(false)
                            else this.$router.replace({ name: 'dashboard' })
                        },
                        onClose: () => {
                            if (callback) callback(false)
                            else this.$router.replace({ name: 'dashboard' })
                        },
                        occasion: 'Wedding',
                        ...params,
                    })
                }
            } catch (e) {
                console.error('Birdie failed to load', e)
            }
            return this._birdieInst
        },
    },
})
