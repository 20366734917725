export const state = () => ({})

export const mutations = {}

export const actions = {
    async save({}, { model, toastMessage }) {
        if (!model) throw new Error('Error: [transaction/save] - missing model')
        const result = await this.$axios.$post('/api/v1/user-transactions', model)
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return result
    },
    async delete({}, { id, toastMessage }) {
        if (!id) throw new Error('Error: [transaction/delete] - missing id')
        await this.$axios.$delete(`/api/v1/user-transactions/${id}`)
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return
    },
    async savePosse({}, { user, model, toastMessage }) {
        if (!model) throw new Error('Error: [transaction/savePosse] - missing model')
        if (!user) throw new Error('Error: [transaction/savePosse] - missing user')
        const result = await this.$axios.$post(`/api/v1/user-posses/user/${user}/user-transactions`, model)
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return result
    },
}

export const getters = {}
