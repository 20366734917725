export const state = () => ({})

export const mutations = {}

export const actions = {
    async send({}, { email, event, params, toastMessage }) {
        if (!email || !event) throw new Error('Error: [message/send] - missing email or event')
        await this.$axios.post('/api/v1/i', {
            email: email,
            event: event,
            props: params,
        })
        if (payload.toastMessage) this.$toast.success(payload.toastMessage).goAway(3000)
        return
    },
}

export const getters = {}
