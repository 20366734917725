const urlBase64ToUint8Array = function (base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

export const state = () => ({
    enabled: false,
})

export const mutations = {
    setEnabled(state, enabled) {
        state.enabled = enabled
    },
}

export const actions = {
    check() {
        return new Promise((resolve, reject) => {
            if (!('serviceWorker' in navigator)) {
                reject(new Error("Service workers aren't supported in this browser."))
                return
            }
            if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
                reject(new Error("Notifications aren't supported."))
                return
            }
            if (Notification.permission === 'denied') {
                reject(new Error('The user has blocked notifications.'))
                return
            }
            if (!('PushManager' in window)) {
                reject(new Error("Push messaging isn't supported."))
                return
            }
            resolve()
        })
    },

    init({ commit, dispatch }, user) {
        dispatch('check')
            .then(() => {
                navigator.serviceWorker.ready.then((reg) => {
                    reg.pushManager.getSubscription().then((sub) => {
                        if (user) {
                            const interval = setInterval(() => {
                                if (user.id) {
                                    if (!sub) {
                                        dispatch('subscribe')
                                    } else {
                                        dispatch('updateSubscription', sub).then(() => {
                                            commit('setEnabled', true)
                                        })
                                    }
                                    clearInterval(interval)
                                }
                            }, 100)
                        } else {
                            commit('setEnabled', false)
                        }
                    })
                })
            })
            .catch((e) => {
                console.warn('[PUSH]', e.message ? e.message : ' Error!') // eslint-disable-line no-console
            })
    },

    updateSubscription(obj, sub) {
        const key = sub.getKey('p256dh')
        const token = sub.getKey('auth')

        const data = {
            endpoint: sub.endpoint,
            key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
        }

        return this.$axios.$post(`/api/v1/pushnotifications`, data)
    },

    subscribe({ commit, dispatch, rootState }) {
        dispatch('check')
            .then(() => {
                navigator.serviceWorker.ready.then((reg) => {
                    const options = { userVisibleOnly: true }
                    const vapidPublicKey = rootState.data.vapidKey

                    if (vapidPublicKey) options.applicationServerKey = urlBase64ToUint8Array(vapidPublicKey)

                    reg.pushManager
                        .subscribe(options)
                        .then((sub) => {
                            dispatch('updateSubscription', sub).then(() => {
                                commit('setEnabled', true)
                            })
                        })
                        .catch((e) => console.warn('[PUSH] ', e)) // eslint-disable-line no-console
                })
            })
            .catch((e) => {
                console.warn('[PUSH]', e.message ? e.message : ' Error!') // eslint-disable-line no-console
            })
    },

    unsubscribe({ commit }) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.pushManager.getSubscription().then((subscription) => {
                if (!subscription) {
                    commit('setEnabled', false)
                    return
                }
                subscription.unsubscribe().then(() => {
                    return this.$axios.$delete(`/api/v1/pushnotifications/${subscription.endpoint}`).then(() => {
                        commit('setEnabled', false)
                    })
                })
            })
        })
    },
}
