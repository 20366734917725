
export default {
    props: {
        to: {
            type: [Object, String, undefined],
            default: undefined,
        },
        href: {
            type: [String, undefined],
            default: undefined,
        },
        label: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: 'main',
        },
        type: {
            type: String,
            default: 'button',
        },
        disabled: Boolean,
        back: Boolean,
        loading: Boolean,
        block: Boolean,
        icon: Boolean,
        round: Boolean,
        bold: Boolean,
        semibold: Boolean,
        xxxxs: Boolean,
        xxxs: Boolean,
        xxs: Boolean,
        xs: Boolean,
        sm: Boolean,
        lg: Boolean,
        xl: Boolean,
        auto: Boolean,
        left: Boolean,
        active: Boolean,
    },

    computed: {
        buttonClasses() {
            return [
                'btn-v2',
                `btn-${this.variant}`,
                {
                    'block w-full': this.block,
                    'btn-round': this.round,
                    'btn-icon': this.icon,
                    'btn-bold': this.bold,
                    'btn-semibold': this.semibold,
                    'btn-xxxxs': this.xxxxs,
                    'btn-xxxs': this.xxxs,
                    'btn-xxs': this.xxs,
                    'btn-xs': this.xs,
                    'btn-sm': this.sm,
                    'btn-lg': this.lg,
                    'btn-xl': this.xl,
                    'btn-auto': this.auto,
                    'btn-align-left': this.left,
                    'disabled': this.disabled,
                    active: this.active,
                },
            ]
        },

        ariaLabel() {
            return this.label ? this.label : `button-${this._uid}`
        },
    },

    methods: {
        handleClick(e) {
            if(!this.disabled && !this.loading)
                if (!this.to && !this.back) this.$emit('click', e)
                else if (this.back) this.$router.go(-1)
        },
    },

    render(h) {
        let isNuxtLink = this.to !== undefined
        let isExternalLink = this.href !== undefined
        if (typeof this.to === 'string' && this.to.includes('http')) {
            isNuxtLink = false
            isExternalLink = true
        }
        return h(
            isNuxtLink ? 'nuxt-link' : isExternalLink ? 'a' : 'button',
            {
                class: this.buttonClasses,
                props: {
                    ...(this.to ? {to: this.to} : {}),
                },
                attrs: {
                    'aria-label': this.ariaLabel,
                    ...(this.href && !this.disabled ? {href : this.href} : {}),
                    ...(!this.href && !isNuxtLink ? {href: this.to} : {}),
                    ...(this.href ? {target: '_blank'} : {}),
                    ...(!this.to && !this.href ? {type: this.type} : {}),
                    ...(this.href ? {rel: 'noreferrer'} : {}),
                    disabled: this.disabled,
                    ...this.$attrs,
                },
                on: {
                    click: this.handleClick,
                },
            },
            this.back && !this.$slots.default && !this.label
                ? [h('loverly-v2-icon', { props: { icon: 'long-arrow-left' } })]
                : [
                      h(
                          'transition',
                          { props: { name: 'fade' } },
                          this.loading ? [h('loverly-v2-spinner', { class: 'small absolute left-1/2 -translate-x-1/2 ml-2', key: 'button-spinner' })] : null
                      ),
                      h(
                          'span',
                          {
                              class: [
                                  'relative inline-flex items-center pointer-events-none transition duration-300' ,
                                  { 'w-full text-wrap': this.block, 'filter blur': this.loading, 'justify-center': this.block && !this.left },
                              ],
                          },
                          [this.$slots.default ? this.$slots.default : this.label]
                      ),
                  ]
        )
    },
}
