
import { mapState } from 'vuex'

export default {
    props: {
        small: Boolean,
    },

    async fetch() {
        await this.getIntent()
        this.$store.dispatch('jsonloader/fetchJson', 'payment.json')
    },

    data() {
        return {
            cardInput: null,
            intent: null,
            stripe: null,
        }
    },

    computed: {
        ...mapState({
            content: (state) => state.jsonloader.data['payment.json'] || null,
        }),
    },

    async mounted() {
        if (!this.$stripe) return
        this.stripe = await this.$stripe()
        const elements = this.stripe.elements()

        this.cardInput = elements.create('card', {
            style: {
                base: {
                    iconColor: '#887E73',
                    color: '#887E73',
                    fontWeight: 400,
                    fontSize: '16px',
                    ':-webkit-autofill': {
                        color: '#887E73',
                    },
                },
                '::placeholder': {
                    color: '#887E73',
                },
                invalid: {
                    iconColor: '#e53e3e',
                    color: '#e53e3e',
                },
            },
        })

        if (this.$refs.cardEl) this.cardInput.mount(this.$refs.cardEl)
    },

    methods: {
        async getIntent() {
            try {
                const res = await this.$axios.$get(`/api/v1/payments/setup-intent`)
                this.intent = res.data
            } catch (error) {
                // eslint-disable-next-line
                console.warn(error)
            }
        },

        async verifyCard() {
            this.$toast.info(this.content.notifications.saving_card).goAway(3000)
            const { setupIntent, error } = await this.stripe.confirmCardSetup(this.intent.client_secret, {
                payment_method: {
                    card: this.cardInput,
                },
            })

            if (error) {
                if (error && error.message) this.$toast.error(error.message).goAway(10000)
            } else {
                this.$axios.$post(`/api/v1/payments/payment-methods`, { payment_method: setupIntent.payment_method }).then(() => {
                    this.$store.dispatch('products/fetchPaymentMethods')
                    this.$emit('input', setupIntent.payment_method)
                    this.$toast.success(this.content.notifications.added_card).goAway(3000)
                })
            }
        },
    },
}
