import Vue from 'vue'

export const state = () => ({
    list: [],
    meta: {},
})

export const mutations = {
    SET_LIST(state, data) {
        state.list = data
    },
    SET_META(state, data) {
        state.meta = data
    },
}

export const actions = {
    async getList({ commit }, payload) {
        const result = await this.$axios.$get(`/api/v1/content/list`, {
            params: payload,
        })
        commit('SET_LIST', result.data)
        commit('SET_META', result.meta)
        return result
    },
}

export const getters = {
    list: (state) => state.list,
    meta: (state) => state.meta,
}
