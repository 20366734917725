export const state = () => ({
    item: {},
    boards: [],
    items: [],
    boardsFetched: false,
    boardListFetched: false,
    simple: {
        boards: [],
        items: [],
        vendors: [],
    },
    single: {},
})

export const mutations = {
    setItem(state, data) {
        state.item = data
    },

    boardsFetch(state, payload = true) {
        state.boardsFetched = payload
    },

    boardListFetch(state, payload = true) {
        state.boardListFetched = payload
    },

    setBoards(state, data) {
        state.boards = data
        if (state.boards.findIndex((el) => el.name === 'Loved') === -1) {
            state.boards.unshift({ slug: 'loved', name: 'Loved', items: [] })
        }
    },

    setItems(state, data) {
        state.items = data
    },

    setSimpleList(state, data) {
        state.boardListFetched = true
        state.simple.boards = data.boards || []
        state.simple.items = data.items || []
        state.simple.vendors = data.vendors || []

        if (state.simple.boards) {
            if (state.simple.boards.findIndex((el) => el.name === 'Loved') === -1) {
                state.simple.boards.unshift({ slug: 'loved', name: 'Loved', items: [] })
            }
        }
    },

    setSimpleBoards(state, data) {
        state.simple.boards = data || []
        if (state.simple.boards.findIndex((el) => el.name === 'Loved') === -1) {
            state.simple.boards.unshift({ slug: 'loved', name: 'Loved', items: [] })
        }
    },

    setSimpleItems(state, data) {
        state.simple.items = data || []
    },

    setSingleBoard(state, data) {
        state.single = data || {}
    },

    triggerBoardModal(state, data = {}) {},
}

export const actions = {
    setItem({ commit }, payload) {
        return new Promise((resolve) => {
            commit('setItem', payload)
            resolve()
        })
    },

    async fetchBoards({ commit }, config = undefined) {
        commit('boardsFetch')
        const res = await this.$axios.$get(`/api/v1/users/me/index/boards`, config)
        commit('setBoards', res.data)
        commit('setItems', res.items)
        return res
    },

    async fetchItems({ commit }, config = undefined) {
        const res = await this.$axios.$get(`/api/v1/users/me/index/items`, config)
        commit('setItems', res.data)
        return res
    },

    async fetchBoard({ commit }, slug = undefined) {
        commit('setSingleBoard', null)
        const res = await this.$axios.$get(`/api/v1/users/me/boards/${slug}`)
        commit('setSingleBoard', res.data)
    },

    async fetchBoardsList({ commit, state, rootGetters }, config = undefined) {
        if (!state.boardListFetched) {
            try {
                commit('boardListFetch')
                const res = await this.$axios.$get(`/api/v2/users/me/boards`, {
                    params: {
                        guest_event_ids: rootGetters['user/moodBoardsEventIds']?.length ? rootGetters['user/moodBoardsEventIds'] : undefined,
                    },
                })
                commit('setSimpleList', res.data)
            } catch (e) {
                commit('boardListFetch', false)
            }
        }
    },

    async saveItem({ commit }, model) {
        const res = await this.$axios.post(`/api/v1/users/me/items`, { model })
        const body = await res.data
        commit('setItems', body.data)
        commit(
            'setSimpleItems',
            body.data?.map((el) => el.id)
        )
    },

    async saveItemToBoard({ commit, dispatch }, payload) {
        const res = await this.$axios.post(`/api/v1/users/me/boards`, { model: payload.model, board: payload.board, boards: payload.boards })
        const body = await res.data
        dispatch('boardManager', body)
    },

    async createBoard({ commit, state, dispatch }, payload) {
        const res = await this.$axios.post(`/api/v1/users/me/boards/create`, { ...payload, model: state.item })
        const body = await res.data
        dispatch('boardManager', body)
    },

    async editBoard({ commit, state, dispatch }, payload) {
        const res = await this.$axios.put(`/api/v1/users/me/boards/${payload.slug}`, { ...payload, model: state.item })
        const body = await res.data
        dispatch('boardManager', body)
        commit('boardsFetch', false)
    },

    async deleteBoard({ commit, state, dispatch }, payload) {
        const res = await this.$axios.delete(`/api/v1/users/me/boards/${payload.slug}`)
        const body = await res.data
        dispatch('boardManager', body)
    },

    boardManager({ commit, state, dispatch }, body) {
        commit('boardListFetch', false)
        commit('setBoards', body.data)
        commit('setSimpleBoards', body.data)
        dispatch('fetchBoardsList')
    },

    setBoardFetchFlag({ commit }, payload) {
        commit('boardListFetch', payload)
    },
}
