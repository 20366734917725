import Vue from 'vue'
import download from 'js-file-download'

export const state = () => ({
    list: [],
    all: [],
    events: [],
    meta: {},
    counts: {},
    count: 0,
})

export const mutations = {
    SET_LIST(state, data) {
        state.list = data
    },
    SET_ALL(state, data) {
        Vue.set(state, 'all', data)
    },
    SET_EVENTS(state, data) {
        state.events = data
    },
    SET_META(state, data) {
        state.meta = data
    },
    SET_COUNTS(state, data) {
        state.counts = Array.isArray(data) ? {} : data
    },
    SET_COUNT(state, data) {
        state.count = data
    },
    UPDATE_LIST(state, { index, item }) {
        Vue.set(state.list, index, item)
    },
    UPDATE_EVENT(state, { index, item }) {
        Vue.set(state.events, index, item)
    },
}

export const actions = {
    async save({}, { id, model, toastMessage }) {
        if (!id) throw new Error('Error: [vendor/save] - missing model or id')
        const result = await this.$axios.$post('/api/v1/user-vendors', { vendor_id: id, ...(model || {}) })
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return result
    },
    async savePosse({}, { id, user, model, toastMessage }) {
        if (!user) throw new Error('Error: [vendor/savePosse] - missing user')
        const result = await this.$axios.$post(`/api/v1/user-posses/user/${user}/user-vendors`, { vendor_id: id, ...(model || {}) })
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return result
    },
    async delete({}, { id, toastMessage }) {
        if (!id) throw new Error('Error: [vendor/delete] - missing id')
        await this.$axios.$delete(`/api/v1/user-vendors/${id}`)
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return
    },
    async deleteBulk({}, { ids, toastMessage }) {
        if (!ids.length) throw new Error('Error: [vendor/delete] - missing ids')
        await this.$axios.$post(`/api/v1/user-vendors/destroy-many`, { ids: ids })
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return
    },
    async deletePosse({}, { id, toastMessage }) {
        if (!id || !user) throw new Error('Error: [vendor/savePosse] - missing user or id')
        const result = await this.$axios.$delete(`/api/v1/user-posses/user/${user}/user-vendors/${id}`)
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return
    },
    async getList({ commit }, payload) {
        const result = await this.$axios.$get(`/api/v1/user-vendors`, {
            params: payload,
        })
        commit('SET_LIST', result.data)
        commit('SET_EVENTS', result.guest_wedding_events)
        commit('SET_META', result.meta)
        commit('SET_COUNTS', result.guest_wedding_event_counts)
        commit('SET_COUNT', result.count)
        return result
    },
    async getAll({ commit }) {
        const result = await this.$axios.$get(`/api/v1/user-vendors/all`)
        commit('SET_ALL', result.data)
        return result.data
    },
    async update({}, payload) {
        if (!payload.id) throw new Error('Error: [vendor/update] - missing id')
        const result = await this.$axios.$put(`/api/v1/user-vendors/${payload.id}`, payload)
        if (payload.toastMessage) this.$toast.success('Vendor is successfully updated').goAway(3000)
        return result
    },
    async updateBulk({}, { vendors, toastMessage }) {
        if (!vendors || !Array.isArray(vendors)) throw new Error('Error: [vendor/updateBulk] - missing array of vendors')
        const result = await this.$axios.$post(`/api/v1/user-vendors/update-many`, { vendors })
        if (toastMessage) this.$toast.success(toastMessage).goAway(3000)
        return
    },
    async updatePosse({}, { user, model }) {
        if (!payload.id || !user) throw new Error('Error: [vendor/updatePosse] - missing id or use')
        const result = await this.$axios.$put(`/api/v1/user-posses/user/${user}/user-vendors/${model.id}`, model)
        if (payload.toastMessage) this.$toast.success('Vendor is successfully updated').goAway(3000)
        return result
    },
    updateList({ commit, state }, item) {
        const index = state.list.findIndex((el) => el.id === item?.id)
        if (index === -1) throw new Error('Error: [vendor/updateList] - item not found in list')
        commit('UPDATE_LIST', { index, item })
    },
    updateEvent({ commit, state }, item) {
        const index = state.events.findIndex((el) => el.id === item?.id)
        if (index === -1) throw new Error('Error: [vendor/updateEvent] - item not found in list')
        console.log(index, item)
        commit('UPDATE_EVENT', { index, item })
    },
    async export({ commit }) {
        const result = await this.$axios.$get(`/api/v1/user-vendors/export`, {
            responseType: 'blob',
        })
        download(result, `vendors.xlsx`)
        return result
    },
}
export const getters = {
    list: (state) => state.list,
    all: (state) => state.all,
    events: (state) => state.events,
    meta: (state) => state.meta,
    counts: (state) => state.counts,
    count: (state) => state.count,
}
