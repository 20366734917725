
import { mapState } from 'vuex'
import { parseISO, formatDistanceToNow } from 'date-fns'
import download from 'js-file-download'

export default {
    async fetch() {
        const response = await this.$axios.$get(`/api/v1/users/notifications`, {
            params: {
                page: this.page,
                per_page: this.perPage,
            },
        })
        this.$set(this, 'notifications', [...this.notifications, ...response.data])

        this.lastPage = response?.last_page
    },

    data() {
        return {
            notifications: [],
            page: 1,
            perPage: 6,
            lastPage: 0,
            open: false,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
            content: (state) => state.jsonloader.data['vendor-dashboard.json'] || null,
        }),

        notificationTypes() {
            return this.notificationContent?.notificationTypes || {}
        },
        notificationContent() {
            return this.content && this.content.notificationContent ? this.content.notificationContent : {}
        },
    },

    methods: {
        readNotification(notification, index, force = false) {
            if (['posse-invite', 'possee-invite'].includes(notification?.data.title) && !force) return

            this.$axios.$post('/api/v1/users/notifications/' + notification.id).then((res) => {
                this.$set(this.notifications, index, res)
            })
        },

        getDateText(notification) {
            return notification.created_at ? formatDistanceToNow(parseISO(notification.created_at)) : ''
        },

        showButton(notification) {
            return (
                ['guest_list', 'vendor_manager', 'checklist', 'file', 'posse-invite', 'possee-invite', 'review-done'].includes(notification.data?.title) &&
                !(['posse-invite', 'possee-invite'].includes(notification.data?.title) && notification.read_at)
            )
        },

        downloadItem(url, name = null) {
            if (url) {
                this.$axios
                    .get(url, { responseType: 'arraybuffer' })
                    .then((res) => {
                        download(res.data, `${name || 'file'}.pdf`)
                    })
                    .catch((e) => {
                        if (e.response.status === 402) {
                            this.$toast.error('You dont have permission to see this document').goAway(3000)
                        }
                    })
            }
        },

        accept(notification, index) {
            if (notification.data?.title === 'possee-invite') {
                this.$axios.$put(`/api/v1/user-posses/${notification.data?.params.id}/possee-accept`).then((res) => {
                    this.$nuxt.$emit('open-posse-modal', res.data)
                    this.readNotification(notification, index, true)
                })
            } else {
                this.$axios.$put(`/api/v1/user-posses/${notification.data?.params.id}/accept`).then((res) => {
                    this.readNotification(notification, index, true)
                })
            }
        },

        decline(notification, index) {
            if (notification.data?.title === 'possee-invite')
                this.$axios
                    .$put(`/api/v1/user-posses/${notification.data.params.id}/possee-decline`)
                    .then(() => this.readNotification(notification, index, true))
            else this.$axios.$put(`/api/v1/user-posses/${notification.data.params.id}/decline`).then(() => this.readNotification(notification, index, true))
        },

        getPageUrl(notification) {
            if (notification.data?.params?.content_type === 'real-wedding') return '/real-weddings/' + notification.data.params.slug
            if (notification.data?.params?.content_type === 'vendors') return '/vendors/' + notification.data.params.slug
            return '#'
        },
        openMenu() {
            this.open = true
        },
        closeMenu() {
            if (this.open) this.open = false
        },
        showAvatar(data) {
            return (
                ['posse-invite', 'posse-accept', 'posse-decline', 'posse-delete', 'possee-invite', 'possee-edit-permissions'].includes(data?.title) &&
                data?.params?.user?.avatar
            )
        },
        showEvents(notification) {
            return ['posse-invite', 'possee-edit-permissions'].includes(notification.data?.title)
        },
        getIcon(notification) {
            switch (notification.data?.title) {
                case 'new_email':
                    return { icon: 'envelope', family: 'far' }
                case 'guest_list':
                    return { icon: 'users', family: 'far' }
                case 'vendor_manager':
                    return { icon: 'briefcase', family: 'far' }
                case 'transaction':
                    return { icon: 'dollar-sign', family: 'fas' }
                case 'file':
                    return { icon: 'arrow-to-bottom', family: 'far' }
                case 'checklist':
                    return { icon: 'check-circle', family: 'far' }
                case 'posse-invite':
                case 'posse-accept':
                case 'posse-decline':
                case 'posse-delete':
                case 'possee-invite':
                case 'possee-edit-permissions':
                    return { icon: 'users', family: 'far' }
                default:
                    return { icon: 'bell', family: 'far' }
            }
        },
        getButton(notification) {
            switch (notification.data?.title) {
                case 'guest_list':
                    return [
                        {
                            icon: 'users',
                            family: 'far',
                            to: { name: 'tools-guest-list' },
                            label: this.notificationTypes.guests?.button || 'Open Guest List',
                            class: 'col-span-2',
                        },
                    ]
                case 'vendor_manager':
                    return [
                        {
                            icon: 'briefcase',
                            family: 'far',
                            to: { name: 'tools-vendor-manager' },
                            label: this.notificationTypes.vendorManager?.button || 'Open Vendor Manager',
                            class: 'col-span-2',
                        },
                    ]
                case 'checklist':
                    return [
                        {
                            icon: 'check-circle',
                            family: 'far',
                            to: { name: 'tools-wedding-checklist' },
                            label: this.notificationTypes.checklist?.button || 'Open Checklist',
                            class: 'col-span-2',
                        },
                    ]
                case 'file':
                    return notification.data?.params?.url
                        ? [
                              {
                                  icon: 'arrow-to-bottom',
                                  family: 'far',
                                  click: () => this.downloadItem(notification.data.params.url, notification.data.params.name),
                                  label: this.notificationTypes.file?.button || 'Open File',
                                  class: 'col-span-2',
                              },
                          ]
                        : undefined
                case 'review-done':
                    return [
                        {
                            icon: 'check-circle',
                            family: 'far',
                            href: this.getPageUrl(notification.data),
                            label: this.notificationTypes['review-done']?.button || 'Open Page',
                            class: 'col-span-2',
                        },
                    ]
                case 'posse-invite':
                case 'possee-invite':
                    return notification.data?.params?.id
                        ? [
                              {
                                  icon: 'check-circle',
                                  family: 'far',
                                  click: (index) => this.accept(notification, index),
                                  label: this.notificationTypes.posseInvite?.accept || 'Accept',
                              },
                              {
                                  icon: 'times',
                                  family: 'far',
                                  click: (index) => this.decline(notification, index),
                                  label: this.notificationTypes.posseInvite?.decline || 'Decline',
                              },
                          ]
                        : undefined
                default:
                    return undefined
            }
        },
    },
}
